'use client';

import { useState } from 'react';
import { redirect } from 'next/navigation';
import {
  TextInput,
  PasswordInput,
  Title,
  Container,
  Button,
  Stack,
  Text,
  Card,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQueryState } from 'nuqs';
import { isEmail } from '@/utils';
import { CenteredLogo, InstantLink, LegalFooter } from '@/components';
import { login } from './actions';
import { schema } from './validation';

interface LoginForm {
  email: string;
  password: string;
}

export default function LoginPage() {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const redirectUrl = useQueryState('url')[0];

  const form = useForm<LoginForm>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: false,
  });

  const handleSubmit = async (values: LoginForm) => {
    setError(null);
    setLoading(true);
    try {
      const response = await login(values, redirectUrl);
      if (response.error?.code === 'email_not_confirmed') {
        redirect(`/confirm?email=${values.email}`);
      } else if (response.error?.code === 'invalid_credentials') {
        setError('Invalid email or password');
      } else if (response.error) {
        setError(`An error occurred (${response.error.code})`);
      }
      if (response.error) {
        setLoading(false);
      }
    } finally {
      setTimeout(() => setLoading(false), 3000);
    }
  };

  return (
    <Container py={40} h="100%" maw="none" mih="100dvh">
      <CenteredLogo />
      <Container size={420} my={40}>
        <Title
          ta="center"
          fw={900}
          className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400"
        >
          Welcome back
        </Title>
        <Text c="dimmed" size="sm" ta="center" mb={30}>
          Don't have an account yet?{' '}
          <InstantLink
            href="/signup"
            style={{ color: 'inherit', fontWeight: 600 }}
          >
            Sign up
          </InstantLink>
        </Text>

        <Card withBorder shadow="md" p={30} radius="md" mt="xl">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              {error && (
                <Text c="red" ta="center" size="sm">
                  {error}
                </Text>
              )}
              <TextInput
                label="Email"
                placeholder="Your email"
                {...form.getInputProps('email')}
                required
                disabled={loading}
                error={form.errors.email}
                autoFocus
                inputMode="email"
                autoComplete="email"
                autoCapitalize="off"
                type="email"
              />

              <PasswordInput
                label="Password"
                placeholder="Your password"
                {...form.getInputProps('password')}
                required
                disabled={loading}
                error={form.errors.password}
              />

              <Button
                type="submit"
                loading={loading}
                fullWidth
                mt="sm"
                size="md"
                disabled={!form.isValid()}
              >
                Log in
              </Button>
            </Stack>
          </form>
        </Card>

        <Stack gap={4} mt="md">
          <Button
            variant="transparent"
            component={InstantLink}
            href={`/forgot-password${isEmail(form.values.email) ? `?email=${form.values.email}` : ''}`}
          >
            Forgot password?
          </Button>
        </Stack>
      </Container>
      <LegalFooter />
    </Container>
  );
}
